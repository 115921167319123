import defu from 'defu'
import sbjs from 'sourcebuster/dist/sourcebuster'

function getCookiePairs() {
  const cookies = document.cookie.split(';')
  const cookiePairs = {}

  cookies.forEach((cookie) => {
    const parts = cookie.split('=')
    const key = parts[0].trim()
    const value = decodeURIComponent(parts[1])
    cookiePairs[key] = value
  })

  return cookiePairs
}

export async function useSubscribe(email: string) {
  try {
    const { $gtm: { pushData } } = useNuxtApp()
    const { headers } = useHeaders()
    const cookiePairs = getCookiePairs()

    delete headers.value.cookie
    delete headers.value.auth_token
    const refine = defu(headers.value, cookiePairs, sbjs.get.udata)

    await $fetch.raw('https://crm.rang.ai/webhooks/forms/', {
      method: 'POST',
      mode: 'no-cors',
      body: {
        email,
        form_type: 1,
        cookies: refine,
      },
    }).then(() => {
      pushData('subscription', { email })
    })
  }
  catch (e) {
    console.error(e)
  }
}
